const apiUrl = process.env.NEXT_PUBLIC_API_URL;
const headers = {
    "Content-Type"             : "application/json",
    "Strict-Transport-Security": "max-age=63072000; includeSubDomains; preload"
};

export async function saveLog(data, mondayToken) {
    console.log("\nSave log func...");
    console.log("Monday Token Length: ", mondayToken?.length);

    if (!mondayToken) {
        return;
    }

    let requestBody;
    try {
        if (typeof data === 'string') {
            // Обернуть строку в объект
            requestBody = JSON.stringify({ message: data });
        } else if (typeof data === 'object') {
            // Преобразовать объект в JSON
            requestBody = JSON.stringify(data);
        } else {
            throw new Error("Unsupported data type");
        }
    } catch (error) {
        console.error("Error processing request body:", error);
        requestBody = JSON.stringify({ error: "Invalid data format" });
    }

    try {
        const response = await fetch(apiUrl + `/api/logs`,
            {
                method : "POST",
                headers: { ...headers, "Authorization": "Bearer " + mondayToken },
                body   : requestBody
            });
        console.log("Result of Saving to DB:", response);
        if (!response.ok) {
            console.error("Failed to save log to DB");
            return null;
        }

        return response;
    } catch (error) {
        console.error("Save log error", error);
        return null;
    }
}

"use client";
import { useRouter } from "next/navigation";
import { Suspense, useContext, useEffect, useState } from "react";
import "monday-ui-react-core/tokens";
import { getUser } from "@/app/api/user";
import { AppContext } from "@/app/context";
import Loading from "@/app/loading";
import mondaySdk from "monday-sdk-js";
import { showErrorNotification } from "@/app/_util/notificator";
import { useMsal } from "@azure/msal-react";
import { scopes } from "@/app/constants";


export default function Home() {
    const router = useRouter();
    const monday = mondaySdk();
    const { instance, accounts } = useMsal();
    const { state, setState } = useContext(AppContext);
    const [isSpChecked, setIsSpChecked] = useState(false);
    const [isMdUserChecked, setIsMdUserChecked] = useState(false);

    // region check guest/viewer and get monday context
    useEffect(() => {
        try {
            monday.listen("context", res => {
                const { isViewOnly, isGuest } = res.data.user;
                const userStatus = isViewOnly ? "viewer" : isGuest ? "guest" : "user";
                if (userStatus !== "user") return router.push(`/viewer?status=${userStatus}`);
            });

            (async () => {
                const { data } = await monday.get("context");
                let itemValue, instanceId;
                if (data?.instanceType) {
                    instanceId = data.instanceId;
                    itemValue = data.instanceType === "item_view" ? data.itemId
                        : data.instanceType === "board_view" && data.boardId;
                }

                const { data: token } = await monday.get("sessionToken");
                if (!token) {
                    console.error("Failed to get token");
                    showErrorNotification("Failed to fetch your data from Monday. Please reload the page and try again.");
                    return;
                }

                setState(
                    {
                        ...state,
                        instanceId : instanceId,
                        itemId     : itemValue,
                        mondayToken: token,
                        theme      : data.theme,
                        userId     : data.user.id,
                        userStatus : "user"
                    });
                console.log("Monday context is OK\n-------------------------------");
                setIsMdUserChecked(true);
            })();
        } catch (error) {
            console.error("Home: listen error", error);
            showErrorNotification("Failed to define monday user status. Please reload the page and try again.");
        } // eslint-disable-next-line
    }, []);
    // endregion check guest/viewer and get monday context

    // region Check sharepoint auth
    useEffect(() => {
        if (!isMdUserChecked || !instance || !accounts) return;

        (async () => {
            try {
                const response = await instance.acquireTokenSilent({ scopes, account: accounts[0] });
                console.log("SP token renewed successfully:", response.accessToken?.length);
                localStorage.setItem("accessToken", response.accessToken);
                setIsSpChecked(true);
            } catch (error) {
                console.error("Failed renewing sp token:", error);
                router.push(`/login`);
            }
        })();
    }, [instance, accounts, isMdUserChecked]);

    // endregion Check sharepoint auth

    // region check user in DB
    useEffect(() => {
        if (!state.userId || !state.mondayToken || !isSpChecked) return;
        console.log("\nStart Validate User Access...");

        (async () => {
            const { mondayToken, userId } = state;

            try {

                // region get user info from DB
                const userInfo = await getUser(userId, mondayToken);
                const spToken = localStorage.getItem("accessToken");
                if (!spToken || !userInfo) {
                    console.log("There is no token\n----------------------");
                    return router.push(`/login`);
                }
                console.log("User Ok, Monday Token: ", mondayToken.length);
                // endregion get user info from DB

                setState({ ...state, spToken, spUser: accounts[0].username });
                router.push(`/content`);
            } catch (error) {
                console.error(" Validation User Access Error:", error);
                router.push(`/login`);
            }
        })();// eslint-disable-next-line
    }, [state.userId, state.mondayToken, isSpChecked]);
    // endregion check user in DB

    return <Suspense fallback={<Loading/>}></Suspense>;
}

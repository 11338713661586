"use client";
import React, { createContext, useState } from "react";

export const AppContext = createContext(null);

export const ContextProvider = ({ children }) => {
    const [state, setState] = useState({
        contentType: null,
        data       : null,
        fileId     : null,
        folders    : null,
        instanceId : null, // if instanceId === 0 => doc view
        isLoading  : false,
        itemId     : null,
        mondayToken: "",
        navTitle   : null,
        pickedFile : null,
        preview    : null,
        siteId     : null,
        spToken    : "",
        spUser     : null,
        theme      : null,
        userId     : ""
    });

    return (
        <AppContext.Provider value={{ state, setState }}>
            {children}
        </AppContext.Provider>
    );
};